import React from 'react'
import axios from 'axios'

// Components
import PageHelmet from '../components/pageHelmet'
import Navbar from '../components/navbar'
import Header from '../components/sections/header'
import Products from '../components/sections/products'
import About from '../components/sections/about'
import Graphics from '../components/sections/graphics'
import FatBurning, { FatReadMoreType } from '../components/sections/fatBurning'
import Contact, { ContactType } from '../components/sections/contact'
import Footer from '../components/sections/footer'

// Styles
import 'styles/global.scss'

type IndexPageType = {
  serverData: CMSDataType
}

const IndexPage: React.FC<IndexPageType> = ({ serverData }) => {
  if (serverData) {
    return (
      <PageHelmet>
        <Navbar />
        <Header title={serverData.cmsData?.header?.data.attributes.Title} description={serverData.cmsData?.header?.data.attributes.Description} />
        <main>
          <Graphics id="products-image" title="Products" imageUrl={serverData.cmsData?.productsImage?.data?.attributes.Image.data?.attributes.url} />
          <About title={serverData.cmsData?.about?.data.attributes.Title} description={serverData.cmsData?.about?.data.attributes.Description} />
          <Graphics id="graphics" title="Sailor’s Survival Superfood" imageUrl={serverData.cmsData?.graphic?.data?.attributes.Image.data?.attributes.url} />
          <FatBurning ReadMoreObjects={serverData.cmsData?.fatBurningSubpage?.data?.attributes.ReadMore} />

          <Contact founders={serverData.cmsData?.contact?.data?.attributes.Founders} support={serverData.cmsData?.contact?.data?.attributes.Support} />
        </main>
        <Footer />
      </PageHelmet>
    )
  }

  return <p style={{ textAlign: 'center' }}>No CMS Connection</p>
}

export async function getServerData(context) {
  const headerData = await axios.get(`${process.env.GATSBY_STRAPI_URL}/api/header?populate=deep`).catch(ex => null)
  const productsData = await axios.get(`${process.env.GATSBY_STRAPI_URL}/api/products-image?populate=deep`).catch(ex => null)
  const aboutData = await axios.get(`${process.env.GATSBY_STRAPI_URL}/api/about?populate=deep`).catch(ex => null)
  const graphicData = await axios.get(`${process.env.GATSBY_STRAPI_URL}/api/graphic?populate=deep`).catch(ex => null)
  const fatBurningData = await axios.get(`${process.env.GATSBY_STRAPI_URL}/api/fat-burning-subpage?populate=deep`).catch(ex => null)
  const contactData = await axios.get(`${process.env.GATSBY_STRAPI_URL}/api/contact?populate=deep`).catch(ex => null)

  return {
    status: 200,
    props: {
      cmsData: {
        header: headerData?.data,
        productsImage: productsData?.data,
        about: aboutData?.data,
        graphic: graphicData?.data,
        fatBurningSubpage: fatBurningData?.data,
        contact: contactData?.data,
      },
    },
    headers: {},
  }
}

type CMSDataType = {
  cmsData: {
    about: {
      data: {
        attributes: {
          Title: string
          Description: string
        }
      }
    }
    fatBurningSubpage: {
      data: {
        attributes: {
          ReadMore: FatReadMoreType[]
        }
      }
    }
    graphic: {
      data: {
        attributes: {
          Image: {
            data: {
              attributes: {
                url: string
              }
            }
          }
        }
      }
    }
    productsImage: {
      data: {
        attributes: {
          Image: {
            data: {
              attributes: {
                url: string
              }
            }
          }
        }
      }
    }
    header: {
      data: {
        attributes: {
          Title: string
          Description: string
        }
      }
    }
    contact: {
      data: {
        attributes: {
          Founders: ContactType[]
          Support: ContactType[]
        }
      }
    }
  }
}

export default IndexPage

import React from 'react'
import { navigate } from 'gatsby'

import Section from './section'

// @ts-ignore
import background from 'images/photo1.jpg'

type AboutType = {
  title?: string
  description?: string
}

const About: React.FC<AboutType> = ({ title, description }) => {
  return (
    <Section
      data={{
        classId: 'about',
        title: 'About',
        secondTitle: title,
        description: description,
        button: {
          buttonTitle: 'Read More',
          buttonAction: () => navigate('/about'),
        },
        background: {
          backgroundImage: background,
          fill: true,
        },
      }}
    />
  )
}

export default About

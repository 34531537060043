import React from 'react'

import Section from './section'

import 'styles/components/sections/graphics.scss'

type GraphicsType = {
  id: string
  title: string
  imageUrl?: string
}

const Graphics: React.FC<GraphicsType> = ({ imageUrl, id, title }) => {
  const GraphicsComponent = (): JSX.Element => {
    if (imageUrl) {
      const ImageSrc = `${process.env.GATSBY_STRAPI_URL}${imageUrl}`

      return (
        <div className="graphics-component">
          <img className="graphics-image" src={ImageSrc} alt="graphics-image" width="100%" height="100%" />
        </div>
      )
    }

    return <React.Fragment />
  }

  return (
    <Section
      data={{
        classId: id,
        title: title,
        customComponent: {
          component: GraphicsComponent,
          customHeight: 300,
        },
        background: {
          fill: false,
        },
      }}
    />
  )
}

export default Graphics

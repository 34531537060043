import React, { useCallback, useEffect, useRef, useState } from 'react'

import 'styles/components/sections/section.scss'

type ButtonType = {
  buttonTitle: string
  buttonAction: () => void
}

type BackgroundType = {
  backgroundImage?: string
  fill?: boolean
}

type ComponentType = {
  component: () => JSX.Element
  customHeight?: number
}

type SectionType = {
  classId: string
  title?: string
  secondTitle?: string
  description?: string
  button?: ButtonType
  background?: BackgroundType
  customComponent?: ComponentType
}

type SectionTypeRct = { data: SectionType }

const Section = ({ data }: SectionTypeRct): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [containerHeight, setContainerHeight] = useState<number>(700)

  const updateHeight = () => {
    if (containerRef && containerRef.current) {
      setContainerHeight(0) // Reset
      setContainerHeight(containerRef.current.scrollHeight + 100)
    }
  }

  const UpdateHeightInit = useCallback(() => {
    updateHeight()
  }, [])

  useEffect(() => {
    UpdateHeightInit()

    window.addEventListener('resize', updateHeight)

    return () => window.removeEventListener('resize', updateHeight)
  }, [data?.customComponent?.component])

  const RenderBackground = (): JSX.Element => {
    if (data.background?.backgroundImage) {
      const height = data.customComponent?.customHeight || (data.customComponent?.component ? containerHeight : 700)
      return (
        <React.Fragment>
          <div
            className="section-background-container"
            style={{
              minHeight: data.customComponent?.customHeight || height,
            }}>
            <img
              src={data.background.backgroundImage}
              alt={`background-${data.title}`}
              style={{
                minHeight: data.customComponent?.customHeight || height,
              }}
            />
          </div>
          <div
            className="section-background"
            style={{
              opacity: 0.8,
              minHeight: data.customComponent?.customHeight || height,
            }}></div>
        </React.Fragment>
      )
    } else {
      if (data.background?.fill) {
        return <div className="section-background"></div>
      } else return <React.Fragment />
    }
  }

  const RenderButton = (): JSX.Element => {
    if (data.button) {
      return (
        <button onClick={data.button.buttonAction} style={{ marginLeft: 0 }}>
          {data.button.buttonTitle}
        </button>
      )
    } else {
      return <React.Fragment />
    }
  }

  if (data.customComponent) {
    return (
      <section
        className={`section-container${data.background?.fill ? ' fill' : ''} ${data.classId}`}
        style={{
          minHeight: data.customComponent.customHeight || containerHeight,
        }}
        ref={containerRef}>
        <RenderBackground />
        <div
          className="section-custom-component"
          style={{
            minHeight: data.customComponent.customHeight || containerHeight,
          }}>
          <p className="section-title">{data.title}</p>
          <data.customComponent.component />
        </div>
      </section>
    )
  } else {
    return (
      <section className={`section-container${data.background?.fill ? ' fill' : ''} ${data.classId}`}>
        <RenderBackground />
        <p className="section-title">{data.title}</p>
        <div className="section-second-title-container">
          <p className="section-second-title">{data.secondTitle || 'Failed to Fetch'}</p>
        </div>
        <div className="section-description-container">
          <p
            className="section-description"
            dangerouslySetInnerHTML={{
              __html: data.description || 'Failed to Fetch',
            }}></p>
          <RenderButton />
        </div>
      </section>
    )
  }
}

export default Section

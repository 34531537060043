import React from 'react'
import { Link } from 'gatsby'

import 'styles/components/sections/footer.scss'

const Footer = (): JSX.Element => {
  return (
    <footer className="footer-container">
      {/* <div className="element links">
                {<p>
                    <Link to="/tos">Terms of Service</Link>
                </p>
                <p>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                </p>
                <p>
                    <Link to="/refund-policy">Refund Policy</Link>
                </p>}
            </div> */}
      <div className="element site-name">
        <p>Sailor's Food © {new Date().getFullYear()}</p>
      </div>
      <div className="element creator">
        <p className="created-by">Created/Projected by:</p>
        <a href="https://kacperporadnik.github.io/portfolio-website/">Kacper Poradnik</a>
      </div>
    </footer>
  )
}

export default Footer

import React from 'react'

// @ts-ignore
import emailIcon from 'icons/email.svg'
// @ts-ignore
import phoneIcon from 'icons/phone.svg'
// @ts-ignore
import websiteIcon from 'icons/website.svg'

import 'styles/components/contactWidget.scss'

type ContactWidgetType = {
  name: string
  email?: string
  phone?: string
  website?: string
}

const ContactWidget: React.FC<ContactWidgetType> = ({ name, email, phone, website }) => {
  const contactForms: JSX.Element[] = []

  if (email) {
    contactForms.push(
      <div className="contact-form" key="email">
        <img src={emailIcon} alt="contact-email" />
        <a href={`mailto:${email}`}>{email}</a>
      </div>
    )
  }

  if (phone) {
    contactForms.push(
      <div className="contact-form" key="phone">
        <img src={phoneIcon} alt="contact-email" />
        <a href={`tel:${phone.replace(' ', '')}`}>{phone}</a>
      </div>
    )
  }

  if (website) {
    contactForms.push(
      <div className="contact-form" key="website">
        <img src={websiteIcon} alt="contact-email" />
        <a href={`${website}`} target="_blank" rel="noreferrer">
          {website}
        </a>
      </div>
    )
  }

  return (
    <div className="contact-form-container">
      <p>{`> ${name}`}</p>
      <div className="contact">{contactForms}</div>
    </div>
  )
}

export default ContactWidget

import React from 'react'
import { navigate } from 'gatsby'

import Section from './section'

// @ts-ignore
import Background from 'images/header.jpg'

import 'styles/components/sections/header.scss'

type HeaderType = {
  title: string
  description: string
}

const Header: React.FC<HeaderType> = ({ title, description }) => {
  const HeaderComponent = (): JSX.Element => {
    return (
      <header className="header-container">
        <div className="quote-container">
          <p>Your body can do much more...</p>
          <p>... just change the fuel</p>
        </div>
        <h1 className="header">{title || "SAILOR'S SURVIVAL SUPERFOOD"}</h1>
        <div className="description-container">
          <p className="description">{description || 'Failed to Fetch'}</p>
          <button onClick={() => navigate('/introduction')}>Read More</button>
        </div>
      </header>
    )
  }

  return (
    <Section
      data={{
        classId: 'introduction',
        background: {
          backgroundImage: Background,
          fill: true,
        },
        customComponent: {
          component: HeaderComponent,
          customHeight: 900,
        },
      }}
    />
  )
}

export default Header

import React from 'react'
import { navigate } from 'gatsby'

import Section from './section'

// @ts-ignore
import background from 'images/photo2.jpg'

import 'styles/components/sections/fatBurning.scss'

export type FatReadMoreType = {
  Title: string
  Description: string
  Image: {
    Text: string
    Image: {
      data: {
        attributes: {
          url: string
        }
      }
    }
  }
}

type FatBurningType = {
  ReadMoreObjects: FatReadMoreType[]
}

const FatBurning: React.FC<FatBurningType> = ({ ReadMoreObjects }) => {
  const FatBurningElement = ({ title, description }: { title: string; description: string }): JSX.Element => {
    const Navigate = () => {
      navigate(`/fat-burning?read=${title.replace(/ /g, '-')}`)
    }

    return (
      <div className="fat-burning-component-element">
        <p className="fat-burning-component-title">{title}</p>
        <p
          className="fat-burning-component-description"
          dangerouslySetInnerHTML={{
            __html: description,
          }}></p>
        <button onClick={Navigate}>Read More</button>
      </div>
    )
  }

  const FatBurningComponent = (): JSX.Element => {
    const readMoreObjects = ReadMoreObjects.map((obj, index) => {
      const descriptionString = `${obj?.Description?.slice(0, 400)}...`

      return <FatBurningElement title={obj?.Title} description={descriptionString} key={index} />
    }) || [<div key={0}></div>]

    return <div className="fat-burning-component-container">{readMoreObjects}</div>
  }

  return (
    <Section
      data={{
        classId: 'fat-burning',
        title: 'Fat Burning',
        customComponent: {
          component: FatBurningComponent,
        },
        background: {
          backgroundImage: background,
          fill: true,
        },
      }}
    />
  )
}

export default FatBurning

import React from 'react'
import ContactWidget from '../contactWidget'

import Section from './section'

import 'styles/components/sections/contact.scss'

export type ContactType = {
  ContactName: string
  email: string
  phone: string
  website: string
}

type ContactsType = {
  founders?: ContactType[]
  support?: ContactType[]
}

const Contact: React.FC<ContactsType> = ({ founders, support }) => {
  const ContactComponent = (): JSX.Element => {
    const DrawContacts = ({ type }: { type: 'founders' | 'support' }) => {
      const elements: JSX.Element[] = []

      if (founders && type === 'founders') {
        founders.forEach((contact, index) => {
          elements.push(<ContactWidget name={contact?.ContactName} phone={contact?.phone} email={contact?.email} website={contact?.website} key={index} />)
        })
      }

      if (support && type === 'support') {
        support.forEach((contact, index) => {
          elements.push(<ContactWidget name={contact?.ContactName} phone={contact?.phone} email={contact?.email} website={contact?.website} key={index} />)
        })
      }

      return <React.Fragment>{elements}</React.Fragment>
    }

    return (
      <div className="contact-container">
        <div className="contact-wrapper">
          <div className="element">
            <p className="element-header">Founders</p>
            <DrawContacts type="founders" />
          </div>
          <div className="element">
            <p className="element-header">Support</p>
            <ContactWidget name="Site Creator" email="kacperporadnik98@gmail.com" website="https://kacperporadnik.netlify.app" />
            <DrawContacts type="support" />
          </div>
        </div>
      </div>
    )
  }

  return (
    <Section
      data={{
        classId: 'contact',
        title: 'Contact',
        customComponent: {
          component: ContactComponent,
        },
        background: {
          fill: false,
        },
      }}
    />
  )
}

export default Contact
